import { Link } from "react-router-dom";

const HeaderBar = ({ pageHeader, children }) => {
    return (
        <header className="bg-light py-2 px-4 mb-8 d-flex justify-content-between">
            <section>
                <h5 className="mb-2">{pageHeader}</h5>
                {children}
            </section>

            <section className="d-none d-lg-flex align-items-center">
                <section className="me-4">
                    <Link
                        to="/student/dashboard/event"
                        className="position-relative"
                    >
                        <i class="fa-solid fa-calendar-days fs-5"></i>
                    </Link>
                </section>

                <section>
                    <span className="me-3 position-relative">
                        <i className="fs-5 fa-solid fa-bell text-primary">
                            <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                                <span className="visually-hidden">
                                    unread messages
                                </span>
                            </span>
                        </i>
                    </span>
                </section>
            </section>
        </header>
    );
};

export default HeaderBar;
