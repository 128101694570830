import AuthUITemplate from "./AuthUI";
import LoaderIcon from "../../cards/utilities/loader";
import { Link, useNavigate } from "react-router-dom";
import useLogin from "../../../hooks/useLogin";
import { useSearchParams } from "react-router-dom";
import HideShowPassword from "./hideShowPassword";
import { useState, useEffect } from "react";
import GoogleAuthHandler from "./GoogleAuthHandler";
import useAuthRedirect from "../../../hooks/useAuthRedirect";
import { useToast } from "../../../hooks/otherHooks/useToast";

const apiURL = process.env.REACT_APP_API_URL;
const LogIn = () => {
    // Initial Data
    const initialFormData = {
        email: "",
        password: "",
    };
    const [nameParams] = useSearchParams();
    const returnUrl = nameParams.get("ReturnUrl");
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const { error, formData, isStatus200, loading, onChange, onSubmit } =
        useLogin(initialFormData, apiURL);

    // Toast
    const { showToast, redirectPage } = useAuthRedirect(isStatus200);

    const { addToast } = useToast();

    const blogReturnUrl = localStorage.getItem("blogReturnUrl");

    useEffect(() => {
        if (showToast) {
            addToast({
                message: "Login Successful! Redirecting...",
                bg: "success",
            });
        }
    }, [showToast]);

    const handleBlogUrlRedirect = () => {
        navigate(blogReturnUrl);
        localStorage.removeItem("blogReturnUrl");
    };

    useEffect(() => {
        if (redirectPage) {
            if (blogReturnUrl) {
                navigate(blogReturnUrl);
                localStorage.removeItem("blogReturnUrl");
            } else if (returnUrl && returnUrl !== "/login") {
                navigate(returnUrl);
            } else {
                // navigate("/student/dashboard");
            }
        }
    }, [redirectPage, blogReturnUrl, returnUrl, navigate]);

    return (
        <AuthUITemplate title="Log In" errorMsg={error}>
            <form className="row g-3" onSubmit={(e) => onSubmit(e)}>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => onChange(e)}
                            value={formData.email}
                            required
                        />
                        <label for="email">Email address</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type={showPassword ? "text" : "password"}
                            class="form-control position-relative pe-7"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={(e) => onChange(e)}
                            value={formData.password}
                            required
                        />
                        <HideShowPassword
                            hidePassword={showPassword}
                            setHidePassword={setShowPassword}
                        />
                        <label for="password">Password</label>
                    </section>
                </section>
                <section className="d-flex justify-content-between text-primary">
                    <section class="form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                        />
                        <label class="form-check-label" for="exampleCheck1">
                            <small class="fw-bold">Remember Login</small>
                        </label>
                    </section>
                    <section>
                        <Link
                            to="/reset-password"
                            class="text-end text-decoration-none fw-bold"
                        >
                            <small>Forgot Password?</small>
                        </Link>
                    </section>
                </section>

                <section className="col-12 d-grid">
                    <button
                        type="submit"
                        className={
                            loading
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                        }
                    >
                        {loading ? <LoaderIcon /> : null}
                        Log In
                    </button>
                </section>
            </form>
            <section class="mt-3">
                <p class="text-center">
                    Don't have an account?{" "}
                    <Link
                        to="/create-account"
                        class="fw-bold text-decoration-none"
                    >
                        Create Account
                    </Link>
                </p>
            </section>
            <section>
                <GoogleAuthHandler redirectToBlogURL={handleBlogUrlRedirect} />
            </section>
        </AuthUITemplate>
    );
};

export default LogIn;
