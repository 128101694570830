import React, { useEffect } from "react";

const CodeCopyEnhancer = () => {
    useEffect(() => {
        const enhanceCodeBlocks = () => {
            // Select all code blocks that aren't already enhanced
            const codeBlocks = document.querySelectorAll("code:not(.enhanced)");

            codeBlocks.forEach((block) => {
                // Mark as enhanced
                block.classList.add("enhanced");

                // Wrap the code block in a container
                const wrapper = document.createElement("div");
                wrapper.classList.add("code-block-container");
                block.parentNode.insertBefore(wrapper, block);
                wrapper.appendChild(block);

                // Add the copy button
                const copyButton = document.createElement("button");
                copyButton.innerText = "Copy";
                copyButton.className = "copy-button";
                wrapper.appendChild(copyButton);

                // Handle copy functionality
                copyButton.addEventListener("click", () => {
                    navigator.clipboard
                        .writeText(block.innerText)
                        .then(() => {
                            copyButton.innerText = "Copied!";
                            setTimeout(() => {
                                copyButton.innerText = "Copy";
                            }, 2000);
                        })
                        .catch((err) => console.error("Copy failed", err));
                });
            });
        };

        enhanceCodeBlocks(); // Enhance on first render

        // Optional: Enhance again if new content is dynamically added (e.g., from CKEditor)
        const observer = new MutationObserver(() => {
            enhanceCodeBlocks();
        });
        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    return null; // This component does not render anything visible
};

export default CodeCopyEnhancer;
