import './main.css';
import { createContext } from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux';
import store from './store';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from './hooks/useCart';
import { ToastProvider } from './hooks/otherHooks/useToast';
import { RouterProvider } from "react-router-dom";
import { router } from "./routers/router";
import CodeCopyEnhancer from './components/cards/utilities/codeCopyFun';


// Contexts
export const ServiceContext = createContext(null)
export const CompanyInfoContext = createContext(null)
export const JobContext = createContext(null)
export const CategoryContext = createContext(null)
export const ClientContext = createContext(null)
export const CoreValuesContext = createContext(null)
export const TechContext = createContext(null)
export const IndustryContext = createContext(null)
export const WorkProcessContext = createContext(null)
export const TeamContext = createContext(null)
export const TestimonialContext = createContext(null)
export const JobOpeningContext = createContext(null)
export const CourseTrackContext = createContext(null)
export const CourseContext = createContext(null)
export const CourseHeroContext = createContext(null)
export const BlogContext = createContext(null)


function App() {
  const [service, setService] = useState([]);
  const [companyInfo, setCompanyInfor] = useState([])
  const [jobs, setJobs] = useState([])
  const [categories, setCategories] = useState([]);
  const [client, setClient] = useState([])
  const [coreValue, setCorevalue] = useState([])
  const [tech, setTech] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [workProcess, setWorkProcess] = useState([]);
  const [ourTeam, setOurTeam] = useState([])
  const [testimonials, setTestimonials] = useState([])
  const [jobOpenings, setJobOpenings] = useState([])
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([])
  const [courses, setCourses] = useState([])
  const [courseHero, setCourseHero] = useState([])
  const [blog, setBlog] = useState([])

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/testimonials`),
      axios.get(`${process.env.REACT_APP_API_URL}/our-teams`),
      axios.get(`${process.env.REACT_APP_API_URL}/work-process`),
      axios.get(`${process.env.REACT_APP_API_URL}/industries`),
      axios.get(`${process.env.REACT_APP_API_URL}/technologies`),
      axios.get(`${process.env.REACT_APP_API_URL}/core-values`),
      axios.get(`${process.env.REACT_APP_API_URL}/clients`),
      axios.get(`${process.env.REACT_APP_API_URL}/our-services`),
      axios.get(`${process.env.REACT_APP_API_URL}/company-info/1`),
      axios.get(`${process.env.REACT_APP_API_URL}/our-recent-jobs`),
      axios.get(`${process.env.REACT_APP_API_URL}/our-jobs`),
      axios.get(`${process.env.REACT_APP_API_URL}/course/bk-track`),
      axios.get(`${process.env.REACT_APP_API_URL}/course/bk-course`),
      axios.get(`${process.env.REACT_APP_API_URL}/course/bk-course-hero`),
      axios.get(`${process.env.REACT_APP_API_URL}/blog/post-list`),
    ])
      .then((responses) => {
        setTestimonials(responses[0].data);
        setOurTeam(responses[1].data);
        setWorkProcess(responses[2].data);
        setIndustry(responses[3].data);
        setTech(responses[4].data);
        setCorevalue(responses[5].data);
        setClient(responses[6].data);
        setService(responses[7].data);
        setCompanyInfor(responses[8].data);
        setJobs(responses[9].data);
        setJobOpenings(responses[10].data);
        setTracks(responses[11].data);
        setCourses(responses[12].data);
        setCourseHero(responses[13].data);
        setBlog(responses[14].data);

        const uniqueCategories = responses[9].data.reduce((categories, product) => {
          const productCategories = product.category;
          productCategories.forEach(category => {
            const existingCategory = categories.find(c => c.name === category.name);
            if (!existingCategory) {
              categories.push(category);
            }
          });
          return categories;
        }, []);
        setCategories(uniqueCategories);

        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <CartProvider>
          <ServiceContext.Provider value={service}>
            <CompanyInfoContext.Provider value={companyInfo}>
              <JobContext.Provider value={jobs}>
                <CategoryContext.Provider value={categories}>
                  <ClientContext.Provider value={client}>
                    <CoreValuesContext.Provider value={coreValue}>
                      <TechContext.Provider value={tech}>
                        <IndustryContext.Provider value={industry}>
                          <WorkProcessContext.Provider value={workProcess}>
                            <TeamContext.Provider value={ourTeam}>
                              <TestimonialContext.Provider value={testimonials}>
                                <JobOpeningContext.Provider value={jobOpenings}>
                                  <CourseTrackContext.Provider value={tracks}>
                                    <CourseContext.Provider value={courses}>
                                      <CourseHeroContext.Provider value={courseHero}>
                                        <BlogContext.Provider value={blog}>
                                          <ToastProvider>
                                            <CodeCopyEnhancer />
                                            <RouterProvider router={router} />
                                          </ToastProvider>
                                        </BlogContext.Provider>
                                      </CourseHeroContext.Provider>
                                    </CourseContext.Provider>
                                  </CourseTrackContext.Provider>
                                </JobOpeningContext.Provider>
                              </TestimonialContext.Provider>
                            </TeamContext.Provider>
                          </WorkProcessContext.Provider>
                        </IndustryContext.Provider>
                      </TechContext.Provider>
                    </CoreValuesContext.Provider>
                  </ClientContext.Provider>
                </CategoryContext.Provider>
              </JobContext.Provider>
            </CompanyInfoContext.Provider>
          </ServiceContext.Provider >
        </CartProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
